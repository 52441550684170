.navbar {
  background: rgba(56, 89, 130, 1);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}
/* Color Theme Swatches in Hex */
.navy-and-red-3-1-hex { color: #080D19; }
.navy-and-red-3-2-hex { color: #395A82; }
.navy-and-red-3-3-hex { color: #A0D7E5; }
.navy-and-red-3-4-hex { color: #F42E33; }
.navy-and-red-3-5-hex { color: #CCBE8D; }

/* Color Theme Swatches in RGBA */
.navy-and-red-3-1-rgba { color: rgba(8, 13, 24, 1); }
.navy-and-red-3-2-rgba { color: rgba(56, 89, 130, 1); }
.navy-and-red-3-3-rgba { color: rgba(160, 214, 228, 1); }
.navy-and-red-3-4-rgba { color: rgba(244, 45, 51, 1); }
.navy-and-red-3-5-rgba { color: rgba(204, 189, 140, 1); }

/* Color Theme Swatches in HSLA */
.navy-and-red-3-1-hsla { color: hsla(0, 51, 6, 1); }
.navy-and-red-3-2-hsla { color: hsla(0, 39, 36, 1); }
.navy-and-red-3-3-hsla { color: hsla(0, 57, 76, 1); }
.navy-and-red-3-4-hsla { color: hsla(0, 90, 56, 1); }
.navy-and-red-3-5-hsla { color: hsla(0, 38, 67, 1); }

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: whitesmoke;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon2 {
  color: red;
  margin-right: 0.5rem;
  
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
  color: whitesmoke;
}

.nav-item {
  height: fit-content;
  border-bottom: 2px solid transparent;
  color: whitesmoke;
}

.nav-item:hover {
  border-bottom: 2px solid whitesmoke;
}

.nav-links {
  
  color: whitesmoke;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #6F04D9;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;

   

  }

  .navbar-logo {
    color: whitesmoke;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.4rem;
    display: flex;
    margin-right: 1rem;
    flex-wrap: wrap;
    vertical-align: middle;
    flex-direction: row;

  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: RGBA( 5, 151, 242,0.5);
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}
